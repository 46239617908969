import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import React, { ChangeEventHandler, FormEvent, useEffect, useState } from "react";
import { Credentials, querySdkCredentials } from "../../api";
import QwilLogo from "../../components/QwilLogo";
import { loadFields, saveFields } from "./storage";


interface Props {
  onSuccess: (credentials: Credentials, sdkUrl: string) => void;
}

function Login({onSuccess}: Props) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // values for controlled form components
  const [region, setRegion] = useState('');
  const [sdkUrl, setSdkUrl] = useState('https://beta-sdk.qwil.io');
  const [proxySecret, setProxySecret] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiKeySecret, setApiKeySecret] = useState('');
  const [euxu, setEuxu] = useState('');
  const [entityUuid, setEntityUuid] = useState('');

  // processed values
  const [regionUrl, setRegionUrl] = useState('');

  // error states
  const [regionError, setRegionError] = useState('');
  const [sdkUrlError, setSdkUrlError] = useState('');
  const [euxuError, setEuxuError] = useState('');

  useEffect(() => {
    const stored = loadFields();
    if (stored.region) {
      setRegion(stored.region);
      _processRegion(stored.region);
    }

    if (stored.sdkUrl) {
      setSdkUrl(stored.sdkUrl);
      _processSdkUrl(stored.sdkUrl);
    }

    if (stored.euxu) {
      setEuxu(stored.euxu);
      _processEuxu(stored.euxu);
    }

    if (stored.apiKey) {
      setApiKey(stored.apiKey);
    }

    if (stored.apiKeySecret) {
      setApiKeySecret(stored.apiKeySecret);
    }

    if (stored.proxySecret) {
      setProxySecret(stored.proxySecret);
    }

    if (stored.entityUuid) {
      setEntityUuid(stored.entityUuid);
    }
  }, []);

  const canSubmit = [
    // not still loading
    !loading,

    // all fields have values
    region,
    sdkUrl,
    apiKey,
    apiKeySecret,
    proxySecret,
    euxu,

    // all processed values set
    regionUrl,

    // no field errors
    !regionError,
    !sdkUrlError,
    !euxuError,
  ].every(s => !!s);

  const _formChanged = () => {
    setError('');
  }

  const onRegionChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const region = event.target.value.trim().toLowerCase();
    setRegion(region);  // update displayed value in form field
    _processRegion(region);
    _formChanged();
  }

  const onSdkUrlChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const sdkUrl = event.target.value.trim().toLowerCase();
    setSdkUrl(sdkUrl);  // update displayed value in form field
    _processSdkUrl(sdkUrl);
    _formChanged();
  }

  const onApiKeyChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const apiKey = event.target.value.trim();
    setApiKey(apiKey);  // update displayed value in form field
    _formChanged();
  }

  const onApiKeySecretChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const apiKeySecret = event.target.value.trim();
    setApiKeySecret(apiKeySecret);  // update displayed value in form field
    _formChanged();
  }

  const onProxySecretChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const proxySecret = event.target.value.trim();
    setProxySecret(proxySecret);  // update displayed value in form field
    _formChanged();
  }

  const onEuxuChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const euxu = event.target.value.trim().toLowerCase();
    setEuxu(euxu);  // update displayed value in form field
    _processEuxu(euxu);
    _formChanged();
  }

  const onEntityUuidChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const entityUuid = event.target.value.trim().toLowerCase();
    setEntityUuid(entityUuid);  // update displayed value in form field
    _formChanged();
  }

  const _processRegion = (value: string) => {
    setRegionError(''); // reset previous errors, if any

    if (!value) {
      // if empty
      setRegionUrl('');
    } else if (value === 'prospero' || value === 'earl') {
      // if CENTRAL
      setRegionError('Oy! That is a central VPC!');
      setRegionUrl('');
    } else if (value === 'helena') {
      setRegionError('Sorry. helena not supported. Use juliette');
      setRegionUrl('');
    } else if (value.startsWith('juliette')) {
      // let m = value.match(/^(?<vpc>[a-z]+)-(?<deployment>\d+)$/);
      let m = value.match(/^juliette-(?<deployment>\d+)$/);
      if (!m) {
        setRegionError('You need to set a valid deployment number e.g. "juliette-1234"')
        setRegionUrl('');
      } else {
        setRegionUrl(`https://juliette.private.qwil.network/deployment-${m.groups!.deployment}`);
      }
    } else if (!value.match(/^(?<vpc>[a-z]+)$/)) {
      // if contains non-alpha funky chars
      setRegionError('That does not look like a VPC name');
      setRegionUrl('');
    } else {
      // if UAT or PROD
      if (value === 'lucius' || value === 'dull') {
        setRegionUrl(`https://${value}.qwil.org`);
      } else {
        setRegionUrl(`https://${value}.qwil.io`);
      }
    }
  }

  const _processSdkUrl = (value: string) => {
    setSdkUrlError(''); // reset previous errors, if any

    let urlObj;
    try {
      urlObj = new URL(value);
    } catch (_) {
      setSdkUrlError('That is not a URL');
      return;
    }

    if (urlObj.protocol !== 'https:' && urlObj.protocol !== 'http:') {
      setSdkUrlError('Expecting https://');
    }
  }

  const _processEuxu = (value: string) => {
    setEuxuError(''); // reset previous errors, if any

    const match = value.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)
    if (!match) {
      setEuxuError('Not a valid UUIDv4 string');
    }
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!canSubmit) {
      return;
    }

    setError('');
    const result = await querySdkCredentials({
      region,
      euxu,
      apiKey,
      apiKeySecret,
      proxySecret,
      entityUuid,
    });
    if (result.error) {
      setError(result.error);
    } else {
      saveFields({region, sdkUrl, euxu, apiKey, apiKeySecret, proxySecret});
      onSuccess(result.data as Credentials, sdkUrl);
    }

    setLoading(false);

    // TODO: maybe option for custom path and contactTappable
    // TODO: app-error to be displayed in console with some fancy heading so we can tell it apart
  }

  const commonFieldParams = {
    fullWidth: true,
    disabled: loading,
  }

  return (
    <Box sx={{
      height: '100%',
      overflow: 'hidden',
      overflowY: 'scroll',
    }}>
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

        }}>

        <QwilLogo title={'Qwil SDK Tester'}/>

        <Box marginTop={2} sx={{width: '80%', maxWidth: 600}}>
          <Box component="form" noValidate sx={{mt: 2}} onSubmit={onSubmit}>

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="SDK URL"
              name="sdkUrl"
              error={!!sdkUrlError}
              value={sdkUrl}
              onChange={onSdkUrlChange}
              helperText={sdkUrlError ? sdkUrlError : 'Qwilight URL, e.g. https://sdk.qwil.io or https://beta-sdk.qwil.io'}
            />

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="Region"
              name="region"
              onChange={onRegionChange}
              value={region}
              error={!!regionError}
              helperText={
                regionError ? regionError
                  : regionUrl ? regionUrl
                    : "VPC name, e.g. 'portia', 'lucius', 'juliette-1234'"
              }
            />

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="Proxy Secret"
              name="proxySecret"
              helperText={'We need to relay via private proxy to workaround CORS restrictions'}
              value={proxySecret}
              onChange={onProxySecretChange}
            />

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="Sys Api Key"
              name="apiKey"
              value={apiKey}
              onChange={onApiKeyChange}
            />

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="Api Key Secret"
              name="apiKeySecret"
              value={apiKeySecret}
              onChange={onApiKeySecretChange}
            />

            <TextField
              {...commonFieldParams}
              required={true}
              margin="normal"
              label="User EUXU"
              name="euxu"
              error={!!euxuError}
              helperText={euxuError ? euxuError : ''}
              value={euxu}
              onChange={onEuxuChange}
            />

            <TextField
              {...commonFieldParams}
              required={false}
              margin="normal"
              label="(Optional) Entity Uuid"
              name="entityUuid"
              helperText={'Only set this if you use a Super API Key'}
              value={entityUuid}
              onChange={onEntityUuidChange}
            />

            {error ? (
              <Box marginTop={3}>
                <Alert severity="error">{error}</Alert>
              </Box>
            ) : null}


            <Box marginTop={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!canSubmit || loading}
                sx={{mt: 1, mb: 2}}
              >
                {loading ? <CircularProgress size={25} color={'inherit'}/> : "Log in"}
              </Button>
            </Box>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
