const STORAGE_KEY = 'sdk_tester_stored_fields';


export interface StoredFields {
  region?: string;
  sdkUrl?: string;
  apiKey?: string;
  entityUuid?: string;
  apiKeySecret?: string;
  proxySecret?: string;
  euxu?: string;
}


export function saveFields(data: StoredFields) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

export function loadFields(): StoredFields {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
}

