import { Alert, Box, Button } from "@mui/material";
import QwilApi from "qwil-iframe-api";
import React, { useEffect, useRef, useState } from "react";
import { Credentials } from "../../api";
import { clog, snippet } from "../../colourLog";

interface Props {
  credentials: Credentials;
  sdkUrl: string;
}

function QwilWrapper({ credentials, sdkUrl }: Props) {
  const [initError, setInitError] = useState('');
  const ref = useRef<HTMLElement>()
  const isDevServer = credentials.api_base_url.startsWith('https://juliette');
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const q = new QwilApi({
      token: credentials.sdk_token,
      endpoint: credentials.api_base_url,
      targetElement: ref.current,
      options: {
        contactsTappable: true,
        customUrl: sdkUrl,
      },
      onError: (err) => setInitError(err.message),
      onLoad: (api) => {
        clog([snippet('successPill', 'QwilAPI auth successful')]);

        api.on('app-error', (payload) => {
          clog([
            snippet('errorPill', 'app-error'),
            snippet('errorText', (payload as any).message),
          ])
        });

        api.on('auth-expired', (payload) => {
          clog([snippet('errorPill', 'Credentials have expired')]);
        });

        api.on('click-on-contact', (payload) => {
          clog([snippet('eventPill', 'click-on-contact')], payload);
        });
      },
    });

    return () => q.destroy();
  }, [sdkUrl, credentials]);

  if (initError) {
    return (
      <Box padding={2}>
        <Alert severity="error">
          {initError}
          {isDevServer ? (
            <Box marginTop={1}>
              Looks like you're connecting to a dev server. Do you have your VPN connected?
            </Box>
          ) : null}
        </Alert>
        <Box textAlign={'center'} marginTop={2}>
          <Button onClick={() => window.location.reload()}>Reload</Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%'}}>
      <Box component={'div'} ref={ref} sx={{ height: '100%'}}/>
    </Box>
  );
}

export default QwilWrapper;
