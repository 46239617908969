const styles = {
  successPill: [
    'background-color: green',
    'border: 1px solid white',
    'border-radius: 0.4rem',
    'color: white',
    'padding: 4px',
    'margin-right: 5px',
  ].join(';'),

  eventPill: [
    'background-color: darkblue',
    'border: 1px solid white',
    'border-radius: 0.4rem',
    'color: white',
    'padding: 4px',
    'margin-right: 5px',
  ].join(';'),

  errorPill: [
    'background-color: darkred',
    'border: 1px solid white',
    'border-radius: 0.4rem',
    'color: white',
    'padding: 4px',
    'margin-right: 5px',
  ].join(';'),

  errorText: 'color: red',
}

type StyleName = keyof typeof styles;

type Snippet = {
  style: StyleName,
  text: string;
}

export const snippet = (style: StyleName, text: string): Snippet =>   ({ style, text });

export function clog(snippets: (Snippet | string)[], ...more: any[]) {
  // https://developer.chrome.com/docs/devtools/console/format-style
  const args = ['%c%s'.repeat(snippets.length)];
  snippets.forEach((snippet: Snippet | string) => {
    if (typeof snippet === 'string') {
      args.push('');
      args.push(snippet);
    } else {
      const { style, text } = snippet;
      args.push(styles[style]);
      args.push(text);
    }

  })

  console.log(...args, ...more);
}
