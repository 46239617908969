const ProxyEndpoint = 'https://fznjis6jjma62x6yvtkbisjisq0xqbvz.lambda-url.eu-west-1.on.aws';

export type Credentials = {
  api_base_url: string;
  entity_user_xref_uuid: string;
  entity_uuid: string;
  sdk_token: string;
}

type ErrorData = {
  error_code: string;
}

interface CredentialsQueryProps {
  region: string;
  apiKey: string;
  apiKeySecret: string;
  euxu: string;
  proxySecret: string;
  entityUuid?: string;
}

export async function querySdkCredentials({region, apiKey, apiKeySecret, euxu, proxySecret, entityUuid}: CredentialsQueryProps) {

  return await fetch(
    // Use proxy to workaround CORS restrictions
    `${ProxyEndpoint}/${region}/sdk/sessions/create`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-SYS-API-KEY': apiKey,
        'X-SYS-API-KEY-SECRET': apiKeySecret,
        ...(entityUuid ? {'on-behalf-of-entity-uuid': entityUuid} : {}),
      },
      body: JSON.stringify({
        secret: proxySecret,
        'entity_user_xref_uuid': euxu,
      }),
    }
  ).then(async (response) => {
    if (response.status > 499) {
      return {
        error: `API call failed with status code ${response.status}`,
        data: null,
      };
    }

    let payload;
    const rawPayload = await response.text();

    try {
      payload = JSON.parse(rawPayload);
    } catch (_) {
      console.error(`Non-JSON response from server - ${(rawPayload)}`)
      return {
        error: 'Non-JSON error response. See console for details.',
        data: null,
      }
    }

    if (response.status === 200) {
      return {
        error: null,
        data: payload as Credentials,
      };
    } else {
      return {
        error: `API call failed with error "${(payload as ErrorData).error_code}" and status ${response.status}`,
        data: null,
      }
    }
  }).catch((error) => {
    console.error(error);
    return {
      error: `API call failed. See console for details.`,
      data: null,
    };
  })
}
