import { useCallback, useState } from "react";
import { Credentials } from "../../api";
import Login from "../Login";
import QwilWrapper from "../QwilWrapper";


// TODO: page not scrollable
// TODO: iframe not full screen

function App() {
  const [credentials, setCredentials] = useState<Credentials>();
  const [sdkUrl, setSdkUrl] = useState('');

  console.log('test')
  const onSuccess = useCallback((credentials: Credentials, sdkUrl: string) => {
    setCredentials(credentials);
    setSdkUrl(sdkUrl);
  }, []);
  if (!credentials) {
    return <Login onSuccess={onSuccess}/>;
  } else {
    return <QwilWrapper credentials={credentials} sdkUrl={sdkUrl} />;
  }

}

export default App;
